<template>
  <div class="main-div">
    <div class="top animate">
      <div class="contact-image">
        <img src="../../../../assets/images/data-processing-icon.svg" alt="" />
        <p>Contact Enrichment Logs</p>
      </div>
      <div class="action-btn">
        <div
          class="single-card-box"
          style=" text-align: center;"
          v-if="!currentlyApiLoading"
        >
          <div
            v-if="
              payloadData.status == 'Completed' || payloadData.status == 'Error'
            "
          >
            <button
              class="secondary-btn-outline btn"
              @click="hanldeCopyToNewModal(payloadData.status)"
            >
              <span>
                Copy To New
              </span>
            </button>
          </div>
          <div
            v-if="
              payloadData.status !== 'Completed' &&
                payloadData.status !== 'Error'
            "
          >
            <button
              class="secondary-btn-outline btn"
              v-bind:class="{
                InRed: payloadData.status == 'In Progress',
                InGreen: payloadData.status == 'Paused',
              }"
              @click="modelOpen(payloadData)"
            >
              <span>
                {{
                  payloadData.status == "In Progress"
                    ? "Pause"
                    : payloadData.status == "Paused"
                    ? "Start"
                    : "Completed"
                }}
              </span>
            </button>
          </div>
        </div>

        <div v-if="!currentlyApiLoading" class="refresh-btn">
          <button class="secondary-btn-outline" @click="handleRouteChange">
            Back
          </button>
        </div>
      </div>
    </div>
    <div class="middle-detail-card">
      <b-card>
        <div class="main-details-card">
          <div class="leftcards" v-if="!currentlyApiLoading">
            <div class="upper-setting-box">
              <div class="inner-single-setting d-flex align-items-center">
                <h6>Start Date:</h6>
                <span>
                  {{
                    payloadData.created_at
                      ? getDateInformat(payloadData.created_at)
                      : ""
                  }}
                </span>
              </div>
              <div class="inner-single-setting d-flex align-items-center">
                <h6>Start Time:</h6>
                <span>
                  {{
                    payloadData.created_at
                      ? getformatDate(payloadData.created_at)
                      : ""
                  }}
                </span>
              </div>
              <div class="inner-single-setting d-flex align-items-center">
                <h6>Credit Used:</h6>
                <span>
                  <div v-if="currentlyApiLoading">
                    <div class="skeleton-loder skeleton-table-h"></div>
                  </div>
                  <p v-else>
                    <span
                      v-if="
                        payloadData.creditUsed !== '' &&
                          payloadData.creditUsed !== null
                      "
                    >
                      {{ payloadData.creditUsed }}
                    </span>
                    <span v-else>0</span>
                  </p>
                </span>
              </div>
              <div class="inner-single-setting d-flex align-items-center">
                <h6>Status:</h6>
                <p
                  v-if="payloadData.status === 'Completed'"
                  class="status-td completed "
                >
                  {{ payloadData.status }}
                </p>
                <p
                  v-else-if="payloadData.status === 'Paused'"
                  class="status-td duplicate paused"
                >
                  {{ payloadData.status }}
                </p>
                <p
                  v-else-if="payloadData.status === 'In Progress'"
                  class="status-td in-progress-patch completed"
                >
                  {{ payloadData.status }}
                </p>
                <p
                  v-else-if="payloadData.status === 'Error'"
                  class="status-td duplicate"
                >
                  {{ payloadData.status }}
                </p>
                <p v-else class="status-td duplicate">
                  {{ payloadData.status }}
                </p>
              </div>
              <div class="inner-single-setting d-flex align-items-center">
                <h6>Remaining Time:</h6>
                <span>
                  {{ estimatedTime }}
                </span>
              </div>
            </div>
            <div class="error-message-main">
              <div
                v-if="
                  payloadData.error_message !== '' &&
                    payloadData.error_message !== null
                "
                class="single-card-box"
              >
                <h4>Error Messages</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="status-td duplicate">
                  {{ payloadData.error_message }}
                </p>
              </div>
            </div>
            <div class="middle-setting-box">
              <div class="single-card-box" v-if="payloadData.enrichmentName">
                <h4>Enrichment Name</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else>
                  <span>
                    {{ payloadData.enrichmentName }}
                  </span>
                </p>
              </div>

              <div class="single-card-box" v-if="payloadData.listType">
                <h4>List Type</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else>
                  <span>
                    {{ payloadData.listType }}
                  </span>
                </p>
              </div>

              <div
                class="single-card-box"
                v-if="payloadData.enrich_all_contacts === 1"
              >
                <h4>Enrich All Contacts</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="R1">
                  <span v-if="payloadData.enrich_all_contacts === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.enrich_all_contacts === 0 ||
                        payloadData.enrich_all_contacts === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div>
              <div
                class="single-card-box"
                v-if="
                  payloadData.enrich_all_contacts === 0 ||
                    payloadData.enrich_all_contacts === null
                "
              >
                <h4>Selected List(s)</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="">
                  <span>
                    <p>
                      {{
                        payloadData.selectedListJsonString
                          ? getListName(payloadData.selectedListJsonString)
                          : "-"
                      }}
                    </p>
                  </span>
                </p>
              </div>
              <div
                class="single-card-box"
                v-if="payloadData.already_enriched === 1"
              >
                <h4>Enrich already enriched Contacts?</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="R1">
                  <span v-if="payloadData.already_enriched === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.already_enriched === 0 ||
                        payloadData.already_enriched === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div>
              <!-- <div
                class="single-card-box"
                v-if="payloadData.enrichFutureContact === 0"
              >
                <h4>Enrich all existing contacts?</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="R1">
                  <span v-if="payloadData.enrichAllExistingContact === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.enrichAllExistingContact === 0 ||
                        payloadData.enrichAllExistingContact === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div> -->
              <!-- <div
                class="single-card-box"
                v-if="payloadData.enrichAllExistingContact === 0"
              >
                <h4>
                  Enrich all existing contacts and future contacts created?
                </h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="R1">
                  <span v-if="payloadData.enrichFutureContact === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.enrichFutureContact === 0 ||
                        payloadData.enrichFutureContact === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div> -->
              <div class="single-card-box">
                <h4>Company Technology</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="R1">
                  <span v-if="payloadData.deep_traffic === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.deep_traffic === 0 ||
                        payloadData.deep_traffic === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div>
              <div class="single-card-box">
                <h4>Company Basic</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="R1">
                  <span v-if="payloadData.company_premium === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.company_premium === 0 ||
                        payloadData.company_premium === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div>
              <div class="single-card-box">
                <h4>Company French</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="R1">
                  <span v-if="payloadData.company_french === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.company_french === 0 ||
                        payloadData.company_french === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div>
              <div class="single-card-box">
                <h4>Company Full</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p class="R1">
                  <span v-if="payloadData.company_full === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.company_full === 0 ||
                        payloadData.company_full === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div>
              <div class="single-card-box">
                <h4>Create and associate a new company?</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="R1">
                  <span v-if="payloadData.associate_company === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.associate_company === 0 ||
                        payloadData.associate_company === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div>
              <div class="single-card-box">
                <h4>Create Task?</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="R1">
                  <span v-if="payloadData.create_task === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.create_task === 0 ||
                        payloadData.create_task === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div>
            </div>

            <div
              v-if="payloadData.create_task == 1"
              class="bottom-settings-box"
            >
              <div class="single-card-box">
                <h4>Task Subject</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else>
                  {{ payloadData.task_subject }}
                </p>
              </div>

              <div class="single-card-box body-task-text">
                <h4>Task Body</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else>
                  {{ payloadData.task_body }}
                </p>
              </div>

              <div class="single-card-box">
                <h4>Task Priority</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else>
                  {{ payloadData.task_priority }}
                </p>
              </div>

              <div class="single-card-box">
                <h4>Task Type</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else>
                  {{ payloadData.task_type }}
                </p>
              </div>
              <div class="single-card-box">
                <h4>Assign to contact’s owner?</h4>
                <div v-if="currentlyApiLoading">
                  <div class="skeleton-loder skeleton-table-h"></div>
                </div>
                <p v-else class="R1">
                  <span v-if="payloadData.taskAssignToOwner === 1">
                    <img
                      src="../../../../assets/images/icons/Group 3567.png"
                      alt=""
                    />
                  </span>
                  <span
                    v-if="
                      payloadData.taskAssignToOwner === 0 ||
                        payloadData.taskAssignToOwner === null
                    "
                  >
                    <img
                      src="../../../../assets/images/icons/crossicon.png"
                      alt=""
                    />
                  </span>
                </p>
              </div>
            </div>
            <!-- <div
              v-if="
                payloadData.status == 'Completed' ||
                  payloadData.status == 'Error'
              "
            >
              <button
                class="secondary-btn-outline btn"
                v-bind:class="{
                  InRed: payloadData.status == 'Completed',
                  InGreen: payloadData.status == 'Error',
                }"
                @click="startEnrichment()"
              >
                <span>
                  {{
                    payloadData.status == "Completed"
                      ? "Start Same Enrichment"
                      : payloadData.status == "Error"
                      ? "Start"
                      : "Completed"
                  }}
                </span>
              </button>
            </div> -->
          </div>
          <div class="leftcards loaderbox" v-else>
            <div class="skeleton-loder skeleton-table-h"></div>
            <div class="skeleton-loder skeleton-table-h"></div>
            <div class="skeleton-loder skeleton-table-h"></div>
            <div class="skeleton-loder skeleton-table-h"></div>
            <div class="skeleton-loder skeleton-table-h"></div>
            <div class="skeleton-loder skeleton-table-h"></div>
            <div class="skeleton-loder skeleton-table-h"></div>
          </div>

          <!-- <div class="single-card-box">
            <h4>last Processed Email</h4>
            <p>{{ payloadData.last_processed_email }}</p>
          </div> -->
          <!-- <div class="single-card-box" style=" text-align: center;">
            <h4>Action</h4>
            <div>
              <button
                v-if="payloadData.status != 'Completed'"
                class="action_button"
                v-bind:class="{
                  InRed: payloadData.status == 'In Progress',
                  InGreen: payloadData.status == 'Paused',
                }"
                @click="modelOpen(payloadData)"
              >
                <span
                  v-bind:class="{
                    colorRed: payloadData.status == 'In Progress',
                    colorGreen: payloadData.status == 'Paused',
                  }"
                >
                  {{
                    payloadData.status == "In Progress"
                      ? "Pause"
                      : payloadData.status == "Paused"
                      ? "Start"
                      : "Completed"
                  }}
                </span>
              </button>
              <svg
                v-if="payloadData.status == 'Completed'"
                width="22"
                height="17"
                viewBox="0 0 22 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                  fill="#20B89E"
                ></path>
              </svg>
            </div>
          </div> -->
          <div class="rightchart ">
            <div v-if="!currentlyApiLoading">
              <div
                v-if="
                  this.completed.length !== 0 ||
                    this.inProgressData.length !== 0 ||
                    this.errorList.length !== 0
                "
              >
                <apexchart
                  width="500"
                  type="donut"
                  :options="chartOptions"
                  :series="[completedCount, inprogressCount, errorCount]"
                ></apexchart>
              </div>
              <div v-else>
                <div class="no-data-box text-center">
                  <img
                    src="../../../../assets/images/icons/no-data.svg"
                    alt=""
                  />
                  <h5 class="mt-4">
                    No contact here to<br />
                    show the graph
                  </h5>
                </div>
              </div>
            </div>
            <div v-else class="rightchart skeletonbox">
              <div class="skeleton-loder skeleton-table-h"></div>
              <div class="skeleton-loder skeleton-table-h roundbox"></div>
            </div>
          </div>
          <modal name="my-modal" class="modaldata">
            <div class="enrich-head">
              <i class="fa fa-times" aria-hidden="true" @click="closeModal"></i>
            </div>
            <div class="enrichmodal" style="padding: 20px;  ">
              <h4 style="text-align: center;  ">
                <span class="text-color">Are you sure,</span><br />
                <span class="modal-subheading">
                  You want to {{ this.actionText }}
                </span>
              </h4>

              <div class="action_button" style="text-align: center;">
                <button
                  @click="enrichAction"
                  class="action_perform secondary-btn-outline"
                >
                  Yes
                </button>
                <button
                  @click="closeModal"
                  class="action_perform secondary-btn-outline"
                >
                  No
                </button>
              </div>
            </div>
          </modal>
        </div>
      </b-card>
    </div>
    <b-card no-body>
      <div class="jobSwitchClass">
        <div class="search-container ">
          <!-- <input type="text" placeholder="Search Contact... " @change="getSearchcontact((e)=>{e.target.value})"    /> -->
          <div class="searchicon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11"
                cy="11"
                r="8"
                stroke="#828282"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 16.9579L21.5 21.958"
                stroke="#828282"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <input
            type="text"
            class="searchbar"
            placeholder="Search by Email ID"
            @blur="getSearchcontact()"
            v-model="searchEmail"
          />
        </div>

        <!-- <b-row class="new-resultss">
          <b-col cols="6" class="d-flex align-items-center"
            ><p class="toggleLable">Job Change Detected</p>
            <div class="result-ne d-flex justify-content-between">
              <label class="switch">
                <b-form-checkbox
                  name="check-button"
                  v-model="isJobChangeDetected"
                  @change="onChangejobDetectedFilter(isJobChangeDetected)"
                  switch
                  size="lg"
                ></b-form-checkbox>
              </label></div
          ></b-col>
        </b-row> -->
      </div>
      <b-tabs pills card>
        <!-- Tab 1 -->
        <b-tab
          :title="getTabTitle('All', this.totalCount)"
          @click="handleTabClick('All')"
        >
          <div
            v-if="finalFileData.length == 0"
            class="start-table text-center py-4"
          >
            <p>No contact found</p>
          </div>
          <div v-if="finalFileData.length != 0">
            <div class="start-table text-center mb-5">
              <div v-if="currentlyApiLoading">
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
                <div class="skeleton-loder skeleton-table-h"></div>
              </div>
              <table v-if="!currentlyApiLoading" class="first-tables table-new">
                <thead>
                  <tr>
                    <th>HubSpot Id</th>
                    <th>Job Change Detected</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th class="td-status-msg">Message</th>
                    <th>Curl Link</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in finalFileData" :key="index">
                    <!-- <td>{{ data.id }}</td> -->
                    <td><a v-bind:href="generateLink(data.id ,payloadData.portal_id)" target="_blank">{{ data.id }}</a></td>

                    <td style="text-align: center">
                      <span v-if="data && 'jobDetected' in data">
                        <span v-if="data.jobDetected === true">
                          <svg
                            width="22"
                            height="17"
                            viewBox="0 0 22 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                              fill="#20B89E"
                            ></path>
                          </svg>
                        </span>
                        <span v-else>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L21 21M21 1L1 21"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </svg>
                        </span>
                      </span>
                      <span v-else>
                        ---
                      </span>
                    </td>

                    <td>
                      {{
                        data.message === "Lacks an email address."
                          ? "----"
                          : data.properties.email
                      }}
                    </td>
                    <td
                      class="status-td"
                      v-bind:class="{
                        completed: data.status == 'success',
                        duplicate: data.status == 'error',
                        in_progress: data.status == 'In Progress',
                      }"
                    >
                      <span>{{
                        data.status != null
                          ? data.status
                          : !data.status
                          ? "In Progress"
                          : ""
                      }}</span>
                    </td>
                    <td>
                      {{
                        data.message != null
                          ? data.message
                          : !data.message
                          ? "This Contact is Currently not Processed"
                          : ""
                      }}
                    </td>
                    <td>
                      <span
                        v-if="
                          data.curl ==
                            'The cURL request is not initiated because the contact does not contain an email address.' ||
                            data.curl ==
                              'No curl request is initiated when the contact information includes a Gmail address.' ||
                            data.curl ==
                              'The curl request is not being made because the contact does not contain the required properties' ||
                            data.curl === ''
                        "
                        >{{
                          data.curl || "No curl request generated yet"
                        }}</span
                      >
                      <button
                        v-else
                        class="databtn"
                        @click="copyCurl(data.curl)"
                      >
                        Copy Curl
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="!currentlyApiLoading" class="pagination">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                @change="page($event)"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
              <div></div>
            </div>
          </div>
        </b-tab>

        <!-- Tab 2 -->
        <b-tab
          :title="getTabTitle('Completed', this.completedCount)"
          @click="handleTabClick('Completed')"
        >
          <div
            v-if="completed.length == 0"
            class="start-table text-center py-4"
          >
            <p>No contact found</p>
          </div>
          <div v-if="completed.length != 0">
            <div class="start-table text-center mb-5">
              <table v-if="!currentlyApiLoading" class="first-tables table-new">
                <thead>
                  <tr>
                    <th>HubSpot Id</th>
                    <th>Job Change Detected</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th class="td-status-msg">Message</th>
                    <th>Curl Link</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in completed" :key="index">
                    <!-- <td>{{ data.id }}</td> -->
                    <td><a v-bind:href="generateLink(data.id,payloadData.portal_id)" target="_blank">{{ data.id }}</a></td>

                    <td style="text-align: center">
                      <span v-if="data && 'jobDetected' in data">
                        <span v-if="data.jobDetected === true">
                          <svg
                            width="22"
                            height="17"
                            viewBox="0 0 22 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                              fill="#20B89E"
                            ></path>
                          </svg>
                        </span>
                        <span v-else>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L21 21M21 1L1 21"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </svg>
                        </span>
                      </span>
                      <span v-else>
                        ---
                      </span>
                    </td>

                    <td>{{ data.properties.email }}</td>
                    <td class="status-td completed ">
                      <span>{{ data.status }}</span>
                    </td>
                    <td>{{ data.message }}</td>
                    <td>
                      <span
                        v-if="
                          data.curl ==
                            'The cURL request is not initiated because the contact does not contain an email address.' ||
                            data.curl ==
                              'No curl request is initiated when the contact information includes a Gmail address.' ||
                            data.curl ==
                              'The curl request is not being made because the contact does not contain the required properties' ||
                            data.curl === ''
                        "
                        >{{
                          data.curl || "No curl request generated yet"
                        }}</span
                      >
                      <button
                        v-else
                        class="databtn"
                        @click="copyCurl(data.curl)"
                      >
                        Copy Curl
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="!currentlyApiLoading" class="pagination">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                @change="page($event)"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
              <div></div>
            </div>
          </div>
        </b-tab>

        <b-tab
          :title="getTabTitle('In Progress', this.inprogressCount)"
          @click="handleTabClick('In Progress')"
        >
          <div
            v-if="this.inProgressData.length == 0"
            class="start-table text-center py-4"
          >
            <p>No contact found</p>
          </div>
          <div v-if="this.inProgressData.length != 0">
            <div class="start-table text-center mb-5">
              <table
                v-if="this.inProgressData.length > 0"
                class="first-tables table-new"
              >
                <thead>
                  <tr>
                    <th>HubSpot Id</th>
                    <th>Job Change Detected</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th class="td-status-msg">Message</th>
                    <th>Curl Link</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in this.inProgressData" :key="index">
                    <!-- <td>{{ data.id }}</td> -->
                    <td><a v-bind:href="generateLink(data.id,payloadData.portal_id)" target="_blank">{{ data.id }}</a></td>
                    <td style="text-align: center">
                      <span v-if="data && 'jobDetected' in data">
                        <span v-if="data.jobDetected === true">
                          <svg
                            width="22"
                            height="17"
                            viewBox="0 0 22 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                              fill="#20B89E"
                            ></path>
                          </svg>
                        </span>
                        <span v-else>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L21 21M21 1L1 21"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </svg>
                        </span>
                      </span>
                      <span v-else>
                        ---
                      </span>
                    </td>
                    <td>{{ data.properties.email }}</td>
                    <td class="status-td in_progress">
                      <span>In Progress</span>
                    </td>
                    <td><span>This Contact is Currently In progress</span></td>
                    <td></td>
                    <td>
                      <span
                        v-if="
                          data.curl ==
                            'The cURL request is not initiated because the contact does not contain an email address.' ||
                            data.curl ==
                              'No curl request is initiated when the contact information includes a Gmail address.' ||
                            data.curl ==
                              'The curl request is not being made because the contact does not contain the required properties' ||
                            data.curl === ''
                        "
                        >{{
                          data.curl || "No curl request generated yet"
                        }}</span
                      >
                      <button
                        v-else
                        class="databtn"
                        @click="copyCurl(data.curl)"
                      >
                        Copy Curl
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="!currentlyApiLoading" class="pagination">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                @change="page($event)"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
              <div></div>
            </div>
          </div>
        </b-tab>
        <!-- Tab 3 -->
        <b-tab
          :title="getTabTitle('Error', this.errorCount)"
          @click="handleTabClick('Error')"
        >
          <div
            v-if="errorList.length == 0"
            class="start-table text-center py-4"
          >
            <p>No contact found</p>
          </div>
          <div v-if="errorList.length != 0">
            <div class="start-table text-center mb-5">
              <div v-if="errorList.length == 0">
                <p>No contact found</p>
              </div>
              <table v-if="errorList.length > 0" class="first-tables table-new">
                <thead>
                  <tr>
                    <th>HubSpot Id</th>
                    <th>Job Change Detected</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th class="td-status-msg">Message</th>
                    <th>Curl Link</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in errorList" :key="index">
                    <!-- <td>{{ data.id }}</td> -->
                    <td><a v-bind:href="generateLink(data.id,payloadData.portal_id)" target="_blank">{{ data.id }}</a></td>
                    <td style="text-align: center">
                      <span v-if="data && 'jobDetected' in data">
                        <span v-if="data.jobDetected === true">
                          <svg
                            width="22"
                            height="17"
                            viewBox="0 0 22 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                              fill="#20B89E"
                            ></path>
                          </svg>
                        </span>
                        <span v-else>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L21 21M21 1L1 21"
                              stroke="#FF0000"
                              stroke-width="2"
                            ></path>
                          </svg>
                        </span>
                      </span>
                      <span v-else>
                        ---
                      </span>
                    </td>
                    <td>
                      {{
                        data.message === "Lacks an email address."
                          ? "----"
                          : data.properties.email
                      }}
                    </td>
                    <td class="status-td duplicate">
                      <span>{{ data.status }}</span>
                    </td>
                    <td>{{ data.message }}</td>
                    <td>
                      <span
                        v-if="
                          data.curl ==
                            'The cURL request is not initiated because the contact does not contain an email address.' ||
                            data.curl ==
                              'No curl request is initiated when the contact information includes a Gmail address.' ||
                            data.curl ==
                              'The curl request is not being made because the contact does not contain the required properties' ||
                            data.curl === ''
                        "
                        >{{
                          data.curl || "No curl request generated yet"
                        }}</span
                      >
                      <button
                        v-else
                        class="databtn"
                        @click="copyCurl(data.curl)"
                      >
                        Copy Curl
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="errorList.length > 0" class="pagination">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                @change="page($event)"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
              <div></div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>

    <div class="row">
      <modal name="delete-modal" class="modaldata">
        <div class="enrich-head">
          <i
            class="fa fa-times"
            aria-hidden="true"
            @click="closeCopyToNewModal"
          ></i>
        </div>
        <div class="enrichmodal newenrichmodal">
          <div>
            <h4 style="text-align: center;  ">
              <span class="text-color">Are you sure,</span><br />
              <span class="modal-subheading">
                {{
                  this.startEnrichmentSelectedButtonType == "Completed"
                    ? "You want to copy the enrichment with same settings."
                    : this.startEnrichmentSelectedButtonType == "Error"
                    ? " You want to start the enrichment."
                    : "You want to Completed the enrichment."
                }}
              </span>
            </h4>
          </div>

          <div class="action_button" style="text-align: center;">
            <button
              @click="startEnrichment()"
              class="action_perform secondary-btn-outline"
            >
              Yes
            </button>

            <button
              @click="closeCopyToNewModal"
              class="action_perform secondary-btn-outline"
            >
              No
            </button>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";
import { BCard, BTabs, BTab } from "bootstrap-vue";
import Vue from "vue";
import {
  GET_CONTACT_ENRICHED_LOGS,
  GET_SEARCH_CONTACT_ENRICHED,
  GET_CONTACT_ENRICHED_TABBED_LOGS,
} from "../../../store/constant/actions.type";
import {
  CONTACT_PROCESSED_STATUS,
  STOP_ENRICHMENT,
} from "../../../store/constant/actions.type";
import VueApexCharts from "vue-apexcharts";
import { analyticsService } from "../../common/services/analytics.service.js";
import { loadingService } from "../../common/services/loading.service";
import {
  // ADD_INTEGRATION,
  // GET_CONNECTIONS,
  // GET_HUBSPOT_CONTACT_LIST,
  // REMOVE_INTEGRATION,
  // HUBSPOT_JOB_CHANGE_INTEGRATION,
  START_HUBSPOT_ENRICHMENT,
  // UPDATE_INTEGRATION,
  // UPDATE_TASK_DATA,
  GET_ENRICH_CONTACT_LIST,
} from "../../../store/constant/actions.type.js";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
export default {
  name: "enrich-flipped-contact",
  components: {
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {
      user_detail: {},
      isJobChangeDetected: false,
      title: "Hello Vue!",
      message: "This is a Vue component.",
      completed: [],
      errorList: [],
      finalFileData: [],
      inProgressData: [],
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      showAll: false,
      completedList: false,
      errorMessage: false,
      payloadData: {},
      totalCount: 0,
      inprogressCount: 0,
      errorCount: 0,
      completedCount: 0,
      showTable: true,
      actionUser: {},
      actionText: "",
      dataWrap: {},
      estimatedTime: 0,
      creditUsed: 0,
      searchEmail: "",
      startEnrichmentSelectedButtonType: "",
      searchCheck: 0,
      currentlyApiLoading: true,
      pushHubspot: false,
      chartOptions: {
        // // tooltip: {
        //   enabled: true,
        //   custom: function({ seriesIndex, dataPointIndex, w }) {
        //     return `<div>Completed ${w.globals.series[seriesIndex][dataPointIndex]} contact(s)</div>`;
        //   },
        // },
        chart: {
          id: "vuechart-example",
        },
        labels: [],
        colors: ["#4CAF50", "#FFC107", "#FF0000"],
      },
    };
  },
  methods: {
    generateLink(id,portal_id) {
      return `https://app.hubspot.com/contacts/${portal_id}/record/0-1/${id}`;
    },
    onChangejobDetectedFilter(event) {
      debugger;
      if (this.searchEmail !== "" && !this.isJobChangeDetected) {
        console.log("entryOne");
        this.user_detail = this.$store.getters.getstoreUser;
        let id = this.$route.params.id;
        this.currentPage = 1;
        let data = {
          id: id,
          offset: 10,
          page: this.currentPage,
        };
        this.getEnrichedLogs(data);
      } else {
        if (this.isJobChangeDetected) {
          console.log("change", event);
          this.handleTabClick("All");
        } else {
          this.user_detail = this.$store.getters.getstoreUser;
          let id = this.$route.params.id;
          this.currentPage = 1;
          let data = {
            id: id,
            offset: 10,
            page: this.currentPage,
          };
          this.getEnrichedLogs(data);
        }
      }
    },
    closeCopyToNewModal() {
      this.$modal.hide("delete-modal");
    },
    hanldeCopyToNewModal(type) {
      this.$modal.show("delete-modal");
      this.startEnrichmentSelectedButtonType = type;
    },
    startEnrichment() {
      let idArray;
      if (this.payloadData.selectedListJsonString) {
        const dataArray = JSON.parse(this.payloadData.selectedListJsonString);
        // Extract ids into an array
        idArray = dataArray.map((item) => item.id);
      }
      // let selectedListStringArray = idArray;
      // selectedListStringArray = this.payloadData.selectedListJsonString;
      // this.submitted = true;
      // // if (
      //   this.taskSubjectData == "" ||
      //   this.taskBodyData == "" ||
      //   this.taskPriorityData == "" ||
      //   this.taskTypeData == ""
      // ) {
      //   this.flashMessage.error({
      //     message: "Task Subject and Task Body are required.",
      //   });
      //   return;
      // }
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // }

      let email = localStorage.getItem("user_email");
      let userCredit = this.user_detail?.currentCredit;
      let segmentData = {
        email: email,
        userId: email,
        pushOnHubspot: this.pushHubspot,
        autoEnrich: true,
        userCredit,
      };
      // if (this.autoEnrich) {
      segmentData["companyPremium"] = this.payloadData.company_premium;
      segmentData["companyFrench"] = this.payloadData.company_french;
      segmentData["companyFull"] = this.payloadData.company_full;
      segmentData["deepTraffic"] = this.payloadData.deep_traffic;
      // }
      analyticsService.connectHubspot(this.user_detail, segmentData);
      delete segmentData.userCredit;
      // if (this.autoEnrich) {
      let enrichmentPayload = {
        enrichFutureContact: this.payloadData.enrichFutureContact
          ? true
          : false,
        enrichAllExistingContact: this.payloadData.enrichAllExistingContact
          ? true
          : false,
        listType: this.payloadData.listType,
        enrichmentName: this.payloadData.enrichmentName,
        companyPremium: this.payloadData.company_premium ? true : false,
        companyFrench: this.payloadData.company_french ? true : false,
        companyFull: this.payloadData.company_full ? true : false,
        integration_name: "hubspot",
        createAssociateNewComapanyIfJobChangeDetect: this.payloadData
          .associate_company
          ? true
          : false,
        taskCreationOptional: this.payloadData.create_task ? true : false,
        enrichAllHubspotContact: this.payloadData.enrich_all_contacts
          ? true
          : false,
        selectedContactList: idArray,
        selectedListJsonString: this.payloadData.selectedListJsonString,
        enrichAlreadyEnrichedContacts: this.payloadData.already_enriched
          ? true
          : false,
        deepTraffic: this.payloadData.deep_traffic ? true : false,
        taskPriorityData: this.payloadData.task_priority,
        taskTypeData: this.payloadData.task_type,
        taskAssignToOwner: this.payloadData.taskAssignToOwner,
      };
      if (this.payloadData.create_task) {
        enrichmentPayload.taskBodyData = this.payloadData.task_body;
        enrichmentPayload.taskSubjectData = this.payloadData.task_subject;
        enrichmentPayload.taskPriorityData = this.payloadData.task_priority;
        enrichmentPayload.taskTypeData = this.payloadData.task_type;
        enrichmentPayload.taskAssignToOwner = this.payloadData.taskAssignToOwner;
      } else {
        enrichmentPayload.taskBodyData = null;
        enrichmentPayload.taskSubjectData = null;
        enrichmentPayload.taskPriorityData = null;
        enrichmentPayload.taskTypeData = null;
        enrichmentPayload.taskAssignToOwner = null;
      }
      if (this.payloadData.enrich_all_contacts) {
        enrichmentPayload["selectedContactList"] = [];
        enrichmentPayload["selectedListJsonString"] = "";
      }
      loadingService.setloader(true);
      this.$store
        .dispatch(START_HUBSPOT_ENRICHMENT, enrichmentPayload)
        .then((data) => {
          if (data && data.code == "success") {
            this.flashMessage.success({
              message: data.description,
            });
            this.$modal.hide("delete-modal");

            // this.hubspotIntegrated = false;
            // this.createAssociateNewComapanyIfJobChangeDetect = false;
            // this.taskCreationOptional = false;

            this.getEnrichmentData(email);
          } else if (data.code == "Info") {
            loadingService.setloader(false);
            this.flashMessage.error({
              message: data.description,
            });
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          this.hubspotIntegrated = false;
          if (err && err.data && err.data.message) {
            this.flashMessage.error({
              message: err.data.message,
            });
          }
        });
      // } else {
      //   this.hubspotIntegrated = false;
      // }
    },
    getEnrichmentData(email) {
      let payload = {
        offset: 10,
        page: 1,
      };
      this.$store
        .dispatch(GET_ENRICH_CONTACT_LIST, payload)
        .then((data) => {
          loadingService.setloader(false);
          if (data.status == "success" && data.data.data.length > 0) {
            let enrichmentList = data.data.data;
            let inProgressContact = enrichmentList.filter(
              (x) => x.status == "In Progress" && x.email == email
            );
            this.$store.dispatch("changeApitime", 1);
            this.$router.push({
              name: "enrich-details-contact",
              params: { id: inProgressContact[0].id },
            });
            window.location.reload();
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    modelOpen(postData) {
      if (postData.status == "Paused") {
        this.actionText = "start Enrichment";
      } else if (postData.status == "In Progress") {
        this.actionText = "Pause Enrichment";
      } else {
        postData = {};
      }
      this.actionUser = postData;
      this.$modal.show("my-modal");
    },

    handleRouteChange() {
      this.$router.push({ name: "enrich-contact" });
    },
    getDateInformat(d) {
      if (d) {
        let date = new Date(d);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let m, dy;
        if (month <= 9) m = "0" + month;
        else m = month;
        if (day <= 9) dy = "0" + day;
        else dy = day;
        return `${year}-${m}-${dy}`;
      } else {
        return null;
      }
    },
    getformatDate(value) {
      let dateObject = new Date(value);

      let timeIST = dateObject.toLocaleTimeString("en-IN", dateObject);

      return timeIST;
    },
    getListName(value) {
      if (value && value != "") {
        const list = JSON.parse(value);
        return list.map((item) => item.name).join(", ");
      } else {
        alert("I am here");
        return "-";
      }
    },
    enrichAction() {
      loadingService.setloader(true);
      let id = this.$route.params.id;
      let payload = {
        id: id,
        offset: 10,
        page: 1,
      };
      this.$modal.hide("my-modal");
      let data = {
        id: this.actionUser.id,
        status: this.actionUser.status,
        file_name: this.actionUser.file_name,
      };

      if (this.actionUser.status == "Paused") {
        this.$store
          .dispatch(CONTACT_PROCESSED_STATUS, data)
          .then((response) => {
            if (response) {
              this.getEnrichedLogs(payload);
              this.flashMessage.success({
                message: response.result,
              });
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.error,
            });
          });
      } else if (this.actionUser.status == "In Progress") {
        this.$store
          .dispatch(STOP_ENRICHMENT, data)
          .then((response) => {
            if (response.result == "Enrichment Paused Successfully.") {
              this.getEnrichedLogs(payload);
              this.flashMessage.success({
                message: response.result,
              });
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.error,
            });
          });
      }
    },
    closeModal() {
      this.$modal.hide("my-modal");
    },
    handleTabClick(title) {
      const id = this.$route.params.id;
      loadingService.setloader(true);
      if (this.searchCheck == 0) {
        this.currentPage = 1;
        let payload = {
          tabbed: title,
          offset: 10,
          page: this.currentPage,
          id: id,
        };
        this.$store
          .dispatch(GET_CONTACT_ENRICHED_TABBED_LOGS, payload)
          .then((response) => {
            loadingService.setloader(false);
            if (response.status == "success" && response.data.length > 0) {
              if (this.isJobChangeDetected) {
                this.finalFileData = response.data.filter(
                  (item) => "jobDetected" in item && item.jobDetected === true
                );

                this.completed = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "success" &&
                    "jobDetected" in x &&
                    x.jobDetected === true
                  );
                });
                this.errorList = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "error" &&
                    "jobDetected" in x &&
                    x.jobDetected === true
                  );
                });

                this.inProgressData = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "In Progress" &&
                    "jobDetected" in x &&
                    x.jobDetected === true
                  );
                });
              } else {
                this.finalFileData = response.data;
                this.completed = this.finalFileData.filter(
                  (x) => x.status == "success"
                );
                this.errorList = this.finalFileData.filter(
                  (x) => x.status == "error"
                );
                this.inProgressData = this.finalFileData.filter(
                  (x) => x.status == "In Progress"
                );
              }
              this.totalRows = parseInt(response.totalPages) * 10;
              this.totalCount = response.totalCount;

              this.completedCount = response.completedCount;
              this.inprogressCount = response.inProgressCount;
              this.errorCount = response.errorListCount;
              // this.chartOptions.labels[0] = `Completed ${this.completedCount}`;
              // this.chartOptions.labels[1] = `In Progress ${this.inprogressCount}`;
              // this.chartOptions.labels[2] = `Error ${this.errorCount}`;

              this.chartOptions.labels[0] = `Completed`;
              this.chartOptions.labels[1] = `In Progress`;
              this.chartOptions.labels[2] = `Error`;
              // this.currentPage = response.data.currentPage;

              for (let i = 0; i < this.finalFileData.length; i++) {
                this.finalFileData[i].created_at = new Date(
                  this.finalFileData[i].created_at
                );
              }
              loadingService.setloader(false);
            } else {
              loadingService.setloader(false);
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            if (err != undefined) {
              this.flashMessage.error({
                title: "Error",
                message: err.data.description,
              });
            }
          });
      } else {
        let payload = {
          offset: 10,
          page: this.currentPage,
          email: this.searchEmail,
          id: id,
        };
        this.$store
          .dispatch(GET_SEARCH_CONTACT_ENRICHED, payload)
          .then((response) => {
            loadingService.setloader(false);
            if (response.result.length > 0) {
              if (this.isJobChangeDetected) {
                this.finalFileData = this.finalFileData.filter((contact) => {
                  return response.result.some((resultContact) => {
                    return (
                      resultContact.properties.email ===
                        contact.properties.email &&
                        resultContact.properties.firstname ===
                        contact.properties.firstname &&

                        resultContact.properties.lastname ===
                        contact.properties.lastname &&

                        resultContact.properties.hs_object_id ===
                        contact.properties.hs_object_id &&

                      resultContact.jobDetected === true
                    );
                  });
                });
                this.completed = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "success" &&
                    x.jobDetected === true
                  );
                });
                this.errorList = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "error" &&
                    x.jobDetected === true
                  );
                });
                this.inProgressData = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "In Progress" &&
                    x.jobDetected === true
                  );
                });
              } else {
                this.finalFileData = this.finalFileData.filter((contact) => {
                  return response.result.some(
                    (resultContact) =>
                      resultContact.properties.email === contact.properties.email &&
                      resultContact.properties.firstname === contact.properties.firstname &&
                     resultContact.properties.lastname === contact.properties.lastname &&
                     resultContact.properties.hs_object_id === contact.properties.hs_object_id
                  );
                });
                this.completed = this.finalFileData.filter(
                  (x) => x.status == "success"
                );
                this.errorList = this.finalFileData.filter(
                  (x) => x.status == "error"
                );
                this.inProgressData = this.finalFileData.filter(
                  (x) => x.status == "In Progress"
                );
              }
              this.totalRows = parseInt(response.totalPages) * 10;
              this.totalCount = response.totalCount;
              this.completedCount = response.completedCount;
              this.inprogressCount = response.inProgressCount;
              this.errorCount = response.errorListCount;
              // this.chartOptions.labels[0] = `Completed ${this.completedCount}`;
              // this.chartOptions.labels[1] = `In Progress ${this.inprogressCount}`;
              // this.chartOptions.labels[2] = `Error ${this.errorCount}`;
              this.chartOptions.labels[0] = `Completed`;
              this.chartOptions.labels[1] = `In Progress `;
              this.chartOptions.labels[2] = `Error`;
            } else {
              this.finalFileData = [];
              this.flashMessage.info({
                title: "Not Found",
                message: `Contact with search ${this.searchEmail} not found.`,
              });
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            if (err != undefined) {
              this.flashMessage.error({
                title: "Error",
                message: err.data.description,
              });
            }
          });
      }
    },
    getTabTitle(title, count) {
      // if(title == 'All' && count == 13) {
      //   this.showAll = true;
      //   this.completedList = false;
      //   this.errorMessage = false;
      // } else if(title == 'Completed' && count == 3) {
      //   this.showAll = false;
      //   this.completedList = true;
      //   this.errorMessage = false;
      // } else if(title == 'Error' && count == 9) {
      //   this.showAll = false;
      //   this.completedList = false;
      //   this.errorMessage = true;
      // }loadingService.setloader(true);
      // let id = this.$route.params.id;
      // this.currentPage = Number(event);
      if (count === undefined) {
        return `${title}(0)`;
      } else {
        return `${title}(${count})`;
      }
    },

    getEnrichedLogs(data) {
      this.currentlyApiLoading = true;
      loadingService.setloader(false);
      this.$store.dispatch("changeApitime", 2);
      this.$store
        .dispatch(GET_CONTACT_ENRICHED_LOGS, data)
        .then((response) => {
          this.currentlyApiLoading = false;
          if (response.status == "success") {
            this.finalFileData = response.data;
            if (this.searchEmail !== "" && !this.isJobChangeDetected) {
              this.searchCheck = 1;
              this.handleTabClick("All");
            } else {
              console.log("check_the_payload", response.enrichment[0]);
              this.payloadData = response.enrichment[0];
              console.log("this.payloadData", this.payloadData);
              this.completed = this.finalFileData.filter(
                (x) => x.status == "success"
              );
              this.errorList = this.finalFileData.filter(
                (x) => x.status == "error"
              );
              this.inProgressData = this.finalFileData.filter(
                (x) => x.status == "In Progress"
              );
              this.totalRows = parseInt(response.totalPages) * 10;
              this.totalCount =
                response?.totalCount == "undefined" ? 0 : response?.totalCount;
              this.completedCount =
                response?.completedCount == "undefined"
                  ? 0
                  : response?.completedCount;
              this.errorCount =
                response?.errorListCount == "undefined"
                  ? 0
                  : response?.errorListCount;
              this.inprogressCount =
                response?.inProgressCount == "undefined"
                  ? 0
                  : response?.inProgressCount;
              let estimatedTime =
                response.enrichment[0].estimated_time_to_complete;

              let hours = Math.floor(estimatedTime / 3600);
              let minutes = Math.floor((estimatedTime % 3600) / 60);
              let seconds = estimatedTime % 60;
              this.estimatedTime = `${hours}h ${minutes}m ${seconds}s`;
              // this.chartOptions.labels[0] = `Completed ${this.completedCount}`;
              // this.chartOptions.labels[1] = `In Progress ${this.inprogressCount}`;
              // this.chartOptions.labels[2] = `Error ${this.errorCount}`;

              this.chartOptions.labels[0] = `Completed`;
              this.chartOptions.labels[1] = `In Progress`;
              this.chartOptions.labels[2] = `Error`;
              // for (let i = 0; i < this.finalFileData.length; i++) {
              //   this.finalFileData[i].created_at = new Date(
              //     this.finalFileData[i].created_at
              //   );
              // }

              // this.currentPage = response.data.currentPage;
              // for (let i = 0; i < this.finalFileData.length; i++) {
              //   this.finalFileData[i].created_at = new Date(
              //     this.finalFileData[i].created_at
              //   );
              // }
              this.creditUsed = response.enrichment[0].creditUsed;
            }
          } else {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: response.message,
            });
          }
        })
        .catch((err) => {
          if (
            err.status == "false" &&
            err.message == "Please check Enrichment Id"
          ) {
            this.flashMessage.error({
              title: "Error",
              message: "This enrichment is not belong to you.",
            });
            this.$router.push({ name: "enrich-contact" });
            return;
          }
          this.currentlyApiLoading = false;
          throw err;
        });
    },

    getSearchcontact() {
      if (this.searchEmail != "") {
        this.searchCheck = 1;

        loadingService.setloader(true);
        let id = this.$route.params.id;
        let data = {
          id: id,
          email: this.searchEmail,
          limit: 10,
          ofset: 0,
        };
        this.$store
          .dispatch(GET_SEARCH_CONTACT_ENRICHED, data)
          .then((response) => {
            loadingService.setloader(false);
            if (response.result.length > 0) {
              if (this.isJobChangeDetected) {
                this.finalFileData = this.finalFileData.filter((contact) => {
                  return response.result.some((resultContact) => {
                    return (
                      resultContact.properties.email ===
                        contact.properties.email &&

                        resultContact.properties.firstname ===
                        contact.properties.firstname &&

                        resultContact.properties.lastname ===
                        contact.properties.lastname &&

                        resultContact.properties.hs_object_id ===
                        contact.properties.hs_object_id &&


                      resultContact.jobDetected === true
                    );
                  });
                });
                this.completed = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "success" &&
                    x.jobDetected === true
                  );
                });
                this.errorList = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "error" &&
                    x.jobDetected === true
                  );
                });
                this.inProgressData = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "In Progress" &&
                    x.jobDetected === true
                  );
                });
              } else {
                this.finalFileData = this.finalFileData.filter((contact) => {
                  return response.result.some(
                    (resultContact) =>
                    resultContact.properties.email === contact.properties.email &&
                      resultContact.properties.firstname === contact.properties.firstname &&
                     resultContact.properties.lastname === contact.properties.lastname &&
                     resultContact.properties.hs_object_id === contact.properties.hs_object_id
                      
                  );
                });
                this.completed = this.finalFileData.filter(
                  (x) => x.status == "success"
                );
                this.errorList = this.finalFileData.filter(
                  (x) => x.status == "error"
                );
                this.inProgressData = this.finalFileData.filter(
                  (x) => x.status == "In Progress"
                );
              }
              this.totalRows = parseInt(response.totalPages) * 10;
              this.totalCount = response.totalCount;
              // this.completed = response.completedCount;
              this.errorCount = response.errorListCount;
              this.completedCount = response.completedCount;
              this.inProgressCount = response.inProgressCount;
            } else {
              this.finalFileData = [];
              this.flashMessage.info({
                title: "Not Found",
                message: `Contact with search ${this.searchEmail} not found.`,
              });
              this.searchCheck = 0;
              this.handleTabClick("All");
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.message,
            });
            this.searchCheck = 0;
            this.handleTabClick("All");
          });
      } else {
        if (this.isJobChangeDetected) {
          this.searchCheck = 0;
          this.handleTabClick("All");
        } else {
          this.searchCheck = 0;
          let id = this.$route.params.id;
          let data = {
            id: id,
          };
          this.getEnrichedLogs(data);
        }
      }
    },
    page(event) {
      loadingService.setloader(true);
      let id = this.$route.params.id;
      this.currentPage = Number(event);
      if (this.searchCheck == 0) {
        let payload = {
          offset: 10,
          page: this.currentPage,
          id: id,
        };
        this.$store
          .dispatch(GET_CONTACT_ENRICHED_LOGS, payload)
          .then((response) => {
            loadingService.setloader(false);
            if (response.status == "success" && response.data.length > 0) {
              if (this.isJobChangeDetected) {
                this.finalFileData = response.data.filter(
                  (item) => "jobDetected" in item && item.jobDetected === true
                );

                this.completed = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "success" &&
                    "jobDetected" in x &&
                    x.jobDetected === true
                  );
                });
                this.errorList = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "error" &&
                    "jobDetected" in x &&
                    x.jobDetected === true
                  );
                });

                this.inProgressData = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "In Progress" &&
                    "jobDetected" in x &&
                    x.jobDetected === true
                  );
                });
              } else {
                this.finalFileData = response.data;
                this.completed = this.finalFileData.filter(
                  (x) => x.status == "success"
                );
                this.errorList = this.finalFileData.filter(
                  (x) => x.status == "error"
                );
                this.inProgressData = this.finalFileData.filter(
                  (x) => x.status == "In Progress"
                );
              }
              this.totalRows = parseInt(response.totalPages) * 10;
              this.totalCount = response.totalCount;
              // this.completedCount = response.completedCount;
              // this.inprogressCount = response.inProgressCount;
              // this.errorCount = response.errorListCount;
              // this.currentPage = response.data.currentPage;

              for (let i = 0; i < this.finalFileData.length; i++) {
                this.finalFileData[i].created_at = new Date(
                  this.finalFileData[i].created_at
                );
              }
            } else {
              loadingService.setloader(false);
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            if (err != undefined) {
              this.flashMessage.error({
                title: "Error",
                message: err.data.description,
              });
            }
          });
      } else {
        let payload = {
          offset: 10,
          page: this.currentPage,
          email: this.searchEmail,
          id: id,
        };
        this.$store
          .dispatch(GET_SEARCH_CONTACT_ENRICHED, payload)
          .then((response) => {
            loadingService.setloader(false);
            if (response.result.length > 0) {
              // this.finalFileData = this.finalFileData.filter((contact) => {
              //   return response.result.some(
              //     (resultContact) =>
              //       resultContact.properties.email === contact.properties.email
              //   );
              // });
              // this.completed = this.finalFileData.filter(
              //   (x) => x.status == "success"
              // );
              // this.errorList = this.finalFileData.filter(
              //   (x) => x.status == "error"
              // );
              // this.inProgressData = this.finalFileData.filter(
              //   (x) => x.status == "In Progress"
              // );
              // this.totalRows = parseInt(response.totalPages) * 10;
              // this.totalCount = response.totalCount;
              // this.completedCount = response.completedCount;
              // this.inprogressCount = response.inProgressCount;
              // this.errorCount = response.errorListCount;

              if (this.isJobChangeDetected) {
                this.finalFileData = this.finalFileData.filter((contact) => {
                  return response.result.some((resultContact) => {
                    return (
                      resultContact.properties.email ===
                        contact.properties.email &&

                        resultContact.properties.firstname ===
                        contact.properties.firstname &&

                        resultContact.properties.lastname ===
                        contact.properties.lastname &&

                        resultContact.properties.hs_object_id ===
                        contact.properties.hs_object_id &&


                      resultContact.jobDetected === true
                    );
                  });
                });
                this.completed = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "success" &&
                    x.jobDetected === true
                  );
                });
                this.errorList = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "error" &&
                    x.jobDetected === true
                  );
                });
                this.inProgressData = this.finalFileData.filter((x) => {
                  return (
                    "status" in x &&
                    x.status === "In Progress" &&
                    x.jobDetected === true
                  );
                });
              } else {
                this.finalFileData = this.finalFileData.filter((contact) => {
                  return response.result.some(
                    (resultContact) =>
                      resultContact.properties.email === contact.properties.email &&
                      resultContact.properties.firstname === contact.properties.firstname &&
                     resultContact.properties.lastname === contact.properties.lastname &&
                     resultContact.properties.hs_object_id === contact.properties.hs_object_id
                  );
                });
                this.completed = this.finalFileData.filter(
                  (x) => x.status == "success"
                );
                this.errorList = this.finalFileData.filter(
                  (x) => x.status == "error"
                );
                this.inProgressData = this.finalFileData.filter(
                  (x) => x.status == "In Progress"
                );
              }


            } else {
              this.finalFileData = [];
              this.flashMessage.info({
                title: "Not Found",
                message: `Contact with search ${this.searchEmail} not found.`,
              });
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            if (err != undefined) {
              this.flashMessage.error({
                title: "Error",
                message: err.data.description,
              });
            }
          });
      }
    },
    copyCurl(curl) {
      const textarea = document.createElement("textarea");
      textarea.value = curl;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      // Optionally, provide user feedback (e.g., display a message)
      this.flashMessage.success({
        title: "success",
        message: "Curl Copied to ClipBoard",
        time: 3000,
      });
    },
  },
  mounted() {
    const url = process.env.VUE_APP_NODE_URL;
    // const url = "http://localhost:3000";
    const socket = io(url, {
      path: "/dgsocket",
    });
    // Assuming you have a socket instance available as 'socket'

    socket.on("graphData", (graph_data) => {
      if (this.payloadData.file_name === graph_data?.key) {
        this.completedCount = graph_data.completed;
        this.inprogressCount = graph_data.inprogressCount;
        this.errorCount = graph_data.errorCount;
        // this.chartOptions.labels[0] = `Completed ${this.completedCount}`;
        // this.chartOptions.labels[1] = `In Progress ${this.inprogressCount}`;
        // this.chartOptions.labels[2] = `Error ${this.errorCount}`;
        this.chartOptions.labels[0] = `Completed`;
        this.chartOptions.labels[1] = `In Progress`;
        this.chartOptions.labels[2] = `Error`;
      }
    });

    socket.on("enrichmentEvent", (data) => {
      if (this.payloadData.file_name === data?.key) {
        this.finalFileData = data.enrichmentData?.contacts.slice(-10);
        const setData = data.enrichmentData?.contacts;
        let estimatedTime = data.estimatedTime;
        this.completed = setData.filter((x) => x.status == "success");
        this.errorList = setData.filter((x) => x.status == "error");
        this.inProgressData = setData.filter((x) => x.status == "In Progress");
        // this.totalRows = parseInt(response.totalPages) * 10;
        this.totalCount = setData.length;
        let hours = Math.floor(estimatedTime / 3600);
        let minutes = Math.floor((estimatedTime % 3600) / 60);
        let seconds = estimatedTime % 60;
        this.estimatedTime = `${hours}h ${minutes}m ${seconds}s`;
        this.payloadData.creditUsed = data.creditUsed;
      }
    });
    socket.on("final_status", (data) => {
      if (this.payloadData.file_name === data) {
        this.payloadData.status = "Completed";
      }
    });
    socket.on("enrichment_error", (data) => {
      if (this.payloadData.file_name === data.key) {
        this.payloadData.status = "Error";
        this.payloadData.error_message = data.message;
      }
    });
  },
  beforeMount() {
    this.user_detail = this.$store.getters.getstoreUser;
    let id = this.$route.params.id;
    let data = {
      id: id,
      offset: this.perPage,
      page: this.currentPage,
    };
    if (this.$store.getters.gettime == 1) {
      setTimeout(() => {
        this.getEnrichedLogs(data);
      }, 5000);
    } else {
      this.getEnrichedLogs(data);
    }
  },
};
</script>
<style>
body {
  font-family: Semi Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 16px;
}

.top {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: space-between;
}

.top p {
  margin: 0px 10px;
  display: inline-block;
  vertical-align: middle;
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.table_head {
  background: #3f8aff;
  color: #ffff !important;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  width: 8% !important;
}

td {
  align-content: center;
}

/* tr td {
    width: 16%;
  } */
tr.R1 {
  color: #000000;
  width: 14%;
}

.start-table {
  margin-top: 24px;
  overflow-y: auto;
}

.first-tables {
  width: 100%;
  line-height: 45px;
  color: FFFFFF;
}

.start-table.bottom-area.table-custom {
  padding-top: 0;
}

.start-table.bottom-area.table-custom th {
  font-size: 14px;
  line-height: 1.2;
  vertical-align: middle;
}

/* .completed {
      color: #5BB645;
      border-color: #5BB645;

  } */
/* .file_not_found {
      color: #DA4E39;
      border-color: #DA4E39;
  } */
.modaldata ::v-deep .vm--modal {
  padding: 30px !important;
}

.action_button {
  margin-top: 60px;
  margin: auto;
  border: none !important;
}

.action_perform {
  /* width: 40%; */

  margin-left: 20px;
}

.action_perform:first-child {
  margin-left: 0;
}

.in_progress {
  color: #225fee;
}

.duplicate {
  color: #da4e39;
}

.paused {
  color: #225fee;
}

.action_button {
  width: 100%;
  padding: 6px 15px;
}

.enrichmodal {
  position: absolute;
  width: 100%;
  top: 56%;
  transform: translateY(-50%);
}

.enrich-head i {
  color: #003172;
  color: #fff;
}

.enrich-head {
  text-align: right;
  background: #3f6fcc;
  padding-right: 9px;
}

.vm--modal {
  width: 640px !important;
  height: 170px !important;
}

.table-new tr td {
  line-height: 25px;
  padding: 15px 13px;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  width: 13%;
}

.first-tables th {
  text-align: left;
  padding: 2px 13px;
}

.table-new tr td.status-td span {
  border-radius: 50px;
  display: inline-block;
  padding: 2px 9px 0px;
  font-size: 12px;
  font-weight: 700;
  background: #c7e2bc;
  color: green;
}

td span svg {
  height: 16px;
  widows: 16px;
}

p.completed {
  color: #5bb645;
  border: 1px solid #5bb645;
  padding: 3px;
  border-radius: 30px;
}

td.completed span {
  color: #5bb645;
  border: 1px solid #5bb645;
}

td.file_not_found span {
  color: #da4e39;
  border: 1px solid #da4e39;
}

td.in_progress span {
  color: #64b7d5;
  border: 1px solid #64b7d5;
}

td.duplicate span {
  border: 1px solid #da4e39;
  color: #da4e39;
}

.table-new tr td span.processed {
  color: #00a300;
}

.colorGreen {
  color: #00a300;
}

.table-new tr td span.errorProcess {
  color: #ff0000;
}

.start-table p {
  font-weight: 700;
  font-size: 26px;
  color: #000;
  line-height: 32px;
  margin-bottom: 5px;
}

.colorRed {
  color: #ff0000;
}

.action_button {
  border: 1px solid #3f8aff;
  border-radius: 18px;
  background: transparent;
  color: #3f8aff;
  font-weight: 600;
  text-transform: capitalize;
  /* padding: 5px 0px; */
  transition: 0.8s all;
}

.InRed:hover {
  background: #ff0000;
}

.InRed:hover span {
  color: #fff;
}

.InRed {
  border: 1px solid #ff0000;

  color: #ff0000;
  transition: 0.8s all;
}

.InGreen:hover {
  background: #00a300;
}

.InGreen:hover span.colorGreen {
  color: #fff;
}

input .InGreen {
  color: #00a300;
  transition: 0.8s all;
  border: 1px solid #00a300;
  width: 76px;
  font-size: 14px;
}

.main-div {
  background: #ffffff;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
}

.main-div input {
  border: 1px solid #dfe1e6;
  border-radius: 10px 10px 10px 10px;
  outline: none;
}

.start-table p {
  font-weight: 700;
  font-size: 26px;
  color: #000;
  line-height: 32px;
  margin-bottom: 5px;
}

.custom-tooltip {
  cursor: pointer;
}

.td-status-msg {
  width: 550px;
}

.main-details-card {
  display: flex;
  gap: 40px;
  text-align: left;

  flex-wrap: wrap;
  margin-top: 11px;
}

.main-details-card .single-card-box {
  text-align: center;
}

.single-card-box h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.middle-detail-card {
  margin: 0 0 20px 0;
}

.card-header .nav.nav-pills.card-header-pills {
  justify-content: space-between;
  align-items: center;
  margin: 2px 6px;
}

.action-btn {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: baseline;
}

@media screen and (max-width: 1450px) {
  .main-details-card {
    display: flex;
    justify-content: space-evenly;
    gap: 21px;
  }

  .card-header .nav.nav-pills.card-header-pills {
    justify-content: space-around;
    align-items: center;
    margin: 2px 0px;
  }
}

@media screen and (max-width: 1450px) {
  .td-status-msg {
    width: 450px;
  }
}

.single-card-box .status-td.completed {
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  background: rgba(63, 138, 255, 0.2);
  border: 0;
  padding: 4px 8px;
  color: #3f8aff;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .action_button {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.databtn {
  border: none;
  padding: 4px;
  border-radius: 4px;
  background-color: #20b89e;
  color: #fff;
}

.skeleton-loder {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  will-change: transform;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: loading 2.6s linear infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.skeleton-table-h {
  height: 40px;
}
.jobSwitchClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.toggleLable {
  font-size: 16px;
}
.skeleton-loder:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0) 0,
    rgba(#fff, 0.2) 20%,
    rgba(#fff, 0.5) 60%,
    rgba(#fff, 0)
  );
  animation: shimmer 5s infinite;
  content: "";
  position: absolute;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0)
  );
}

.wrapper .main-content {
  background-color: #f3f7fb;
}

.middle-detail-card .card {
  background-color: transparent;
  border: 0;
}

.top {
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(63, 209, 255, 0.3);
  padding: 20px;
  margin-bottom: 40px;
}

.main-div {
  padding: 0;
  background: transparent;
  box-shadow: none;
}

.main-details-card {
  display: flex;
  gap: 40px;
  text-align: left;
  margin-top: 0;
  flex-wrap: nowrap;
}

.leftcards {
  width: 100%;
  padding: 30px;
  border-radius: 15px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(63, 209, 255, 0.3);
  gap: 24px;
  display: flex;
  flex-direction: column;
  /* padding-left: 64px; */
  position: relative;
}

/* .leftcards::before {
  content: "";
  border: 1px solid #828282;
  position: absolute;
  left: 40px;
  width: 0px;
  height: 90%;
} */

/* .main-details-card .single-card-box::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 31px;
  background: #3f8aff;
  border-radius: 100%;
} */

.card-body {
  flex: auto;
  min-height: 1px;
  padding: 0;
}

.main-details-card .single-card-box {
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-radius: 4.036px;
  border: 0.807px solid #d2d2d2;
  background: #f6f6f6;
  align-items: center;
  height: 40px;
  padding: 0 20px;
}

.single-card-box h4 {
  color: #0f3274;
  font-size: 16.951px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.single-card-box p {
  color: rgba(15, 50, 116, 0.7);
  font-size: 15.336px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.rightchart {
  width: 100%;
  padding: 30px;
  border-radius: 15px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(63, 209, 255, 0.3);
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.rightchart .chartheading {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  left: 30px;
  top: 30px;
}

.top .action-btn .secondary-btn-outline {
  padding: 5px 20px;
  background-color: #3f8aff;
  border: 0;
  color: #fff;
}

.main-div > .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 0;
  padding: 30px;
  border-radius: 24.808px;
  background: var(--White, #fff);
  box-shadow: 0px 4.962px 12.404px 0px rgba(63, 209, 255, 0.3);
}

.main-div > .card .tabs .card-header {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.main-div > .card .tabs .card-header .nav.nav-pills {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
}

.main-div > .card .tabs .card-header .nav.nav-pills li.nav-item {
  width: 100%;
}

.main-div > .card .tabs .card-header .nav.nav-pills li.nav-item .nav-link {
  border-radius: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 18px 20px;
  background-color: #f7f7f7;
  color: #000;
  font-size: 19.847px;
  font-style: normal;
  font-weight: 600;
}

.main-div
  > .card
  .tabs
  .card-header
  .nav.nav-pills
  li.nav-item
  .nav-link.active {
  background-color: #3f8aff;
  color: #ffffff;
}

.main-details-card .single-card-box .R1 {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(90, 175, 75, 0.2);
  border-radius: 40px;
}

.first-tables.table-new thead tr {
  background-color: transparent;
}

.table-new tr:nth-child(odd) {
  background-color: #f7f6fe;
}

.table-new tr td.duplicate span {
  border-radius: 26.049px;
  background: rgba(210, 76, 52, 0.2);
  padding: 4px 15px;
  border: 0;
  color: #d24c34;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
}

.table-new tr td.completed span {
  border-radius: 26.049px;
  background: rgba(32, 184, 158, 0.2);
  padding: 4px 15px;
  border: 0;
  color: #5aaf4b;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
}

.table-new tr td .databtn {
  border-radius: 4.406px;
  background: #3f8aff;
  color: var(--White, #fff);
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 15px;
}

.action_button.InGreen {
  background: transparent;
}

button.action_button.InGreen .colorGreen {
  color: #fff;
}

.main-div > .card .search-container {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.main-div > .card .search-container .searchbar {
  width: 29.5%;
  margin-left: 0;
  padding: 15px 20px;
  padding-left: 50px;
}

.main-div > .card .search-container .searchicon {
  position: absolute;
  left: 15px;
  top: 15px;
}

.table-new tr td.in_progress span {
  border-radius: 26.049px;
  background: rgb(79 139 255 / 30%);
  padding: 4px 15px;
  border: 0;
  color: #4f8bff;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
}

.leftcards.loaderbox::before {
  border-color: #f0f0f0;
}

.rightchart.skeletonbox {
  height: 100%;
  padding: 0;
  box-shadow: none;
  justify-content: space-between;
}

.rightchart.skeletonbox .skeleton-loder.skeleton-table-h.roundbox {
  height: 350px;
  border-radius: 100%;
  width: 350px;
  position: relative;
  top: -60px;
  left: -50px;
}

.single-card-box .status-td.completed {
  background: rgba(32, 184, 158, 0.2);
  color: #5aaf4b;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.in_progress {
  background: rgba(247, 143, 0, 0.2);
  color: #f9c000;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.duplicate {
  background: rgba(210, 76, 52, 0.2);
  color: #d24c34;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.duplicate.paused {
  background: #b8b8b8;
  color: #000;
}

.innerleftcards .single-card-box {
  margin-bottom: 10px;
}

.main-details-card .single-card-box.body-task-text {
  height: auto;
  padding: 6px 6px;
}

.main-details-card .single-card-box.body-task-text h4 {
  width: 350px;
}

.innerleftcards {
  background: #f6f6f6;
  border: 0.807px solid #d2d2d2;
  border-radius: 4.036px;
}

.innerleftcards .single-card-box {
  background: transparent;
  border: unset;
  padding: 0;
}

.upper-setting-box h6 {
  margin: 0 7px 0 0;
  font-weight: bold;
}

.upper-setting-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0 0 10px #eee;
  border-radius: 15px;
  padding: 10px;
}

.inner-single-setting {
  min-width: 155px;
  margin-top: 10px;
}

.inner-single-setting .status-td {
  font-size: 12px;
  padding: 0px 9px;
}

.upper-setting-box p {
  margin: 0;
}

.middle-setting-box .single-card-box {
  background: transparent;
  border: unset;
  width: 50%;
  padding: 0 10px 0 0;
  border-bottom: 1px solid #eee;
}

.middle-setting-box {
  display: flex;
  flex-wrap: wrap;
}

.middle-setting-box .single-card-box h4 {
  text-align: left;
  font-size: 14px;
}

.main-details-card .bottom-settings-box .single-card-box {
  padding: 0;
  background: transparent;
  border: unset;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  margin-bottom: 4px;
}

.main-details-card .bottom-settings-box .single-card-box:last-child {
  margin-bottom: 0px;
  border-bottom: unset;
}

.main-details-card .bottom-settings-box .single-card-box.body-task-text {
  align-items: self-start;
}

.body-task-text h4 {
  text-align: left;
}

.body-task-text p {
  text-align: right;
}

.main-details-card .error-message-main .single-card-box {
  background: transparent;
  padding: 5px;
  border: 1px solid #eee;
}

.inner-single-setting .status-td.duplicate {
  background: #edd4ce;
  border-radius: 15px;
  font-weight: bold;
}
span.modal-subheading {
  font-size: 16px;
}
.new-resultss {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.toggleLable {
  margin: 0;
  margin-right: 10px;
}
.new-resultss .col-6 {
  flex: 0 100%;
  max-width: 100%;
  justify-content: flex-end;
}
</style>
